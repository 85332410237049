/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import chunk from "lodash/chunk"
import some from "lodash/some"
import get from "lodash/get"
import { Flex } from "@theme-ui/components"
import { TabPanel, TabPanels, Tabs } from "@reach/tabs"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useMedia } from "react-use"

import {
  getValueRange,
  mediaQueryList,
  findAndReplaceVariableValue,
} from "../helpers"

import { useIsClient } from "../hooks/use-is-client"
import { useSticky } from "../hooks/use-sticky"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import DesignPartnerDetails from "../components/design-partner-details"
import PageLink from "../components/page-link"
import ProjectCard from "../components/project-card"
import Row from "../components/row"
import SEO from "../components/seo"
import SpecificationsDetails from "../components/specifications-details"
import SpecificationsOverview from "../components/specifications-overview"
import { Tab, TabList } from "../components/custom-tabs"
import Text from "../components/text"

const sharedStyleList = {
  list: {
    container: {
      alignItems: "center",
      display: "flex",
      listStyle: "none",
      m: 0,
      ml: [0, 0, "auto"],
    },
    item: {
      m: 0,
      mr: [2, 4, 5],
      ":last-child": { mr: 0 },
    },
  },
}
const renderCarousel = ({ standardHome }) => {
  const imageList = []

  standardHome.forEach(homeData => {
    if (homeData.images && homeData.images.length) {
      homeData.images.forEach(imageData => {
        imageList.push(imageData)
      })
    }
  })

  return imageList.length ? (
    <Container fullWidth={[true, true, true]}>
      <Row noGutters={[true, true, true]}>
        <Column noGutters={[true, true, true]}>
          <Carousel type="tertiary">
            {imageList.map((contentData, index) => (
              <Img
                sx={{ height: [300, 400, 700] }}
                alt={contentData.title}
                fluid={contentData.fluid}
                key={contentData.id + index}
              />
            ))}
          </Carousel>
        </Column>
      </Row>
    </Container>
  ) : null
}
const renderEfficiencyRichText = richText => {
  return documentToReactComponents(richText, {
    renderNode: {
      [BLOCKS.HEADING_6]: () => null,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text customStyle={{ maxWidth: ["100%", "100%", "70%"] }}>
          {children}
        </Text>
      ),
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { title, file } = node.data.target.fields

        return (
          <div>
            <img
              sx={{ display: "block", mb: 2 }}
              src={file["en-US"].url}
              alt={title["en-US"]}
            />
          </div>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return <PageLink link={node.data.uri}>{children}</PageLink>
      },
    },
  })
}
const renderContentCardDescriptionList = description => {
  const descriptionList = description.json.content.find(
    contentCardDescription =>
      contentCardDescription.nodeType === "unordered-list"
  )
  const descriptionListColumnList =
    get(descriptionList, "content.length") > 5
      ? chunk(
          descriptionList.content,
          Math.ceil(descriptionList.content.length / 2)
        )
      : descriptionList
      ? [descriptionList.content]
      : []

  return (
    <div
      sx={{
        display: ["block", "block", "flex"],
        maxWidth: 690,
        mt: theme => theme.spacing.vertical.md,
        mx: "auto",
      }}
    >
      {descriptionListColumnList.map((contentCardDescriptionColumn, index) => {
        return (
          <ul
            sx={{
              listStyle: "none",
              m: 0,
              mb: [2, 2, 0],
              pr: [0, 0, 6],
              ":last-child": { mb: 0, pr: 0 },
            }}
            key={index}
          >
            {documentToReactComponents(
              {
                content: contentCardDescriptionColumn,
                data: {},
                nodeType: "document",
              },
              {
                renderNode: {
                  [BLOCKS.LIST_ITEM]: node => (
                    <li
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        lineHeight: 1,
                        mb: theme => [2, 2, theme.spacing.horizontal],
                        ":last-child": { mb: 0 },
                        "::before": {
                          content: '"\\2022"',
                          fontSize: 1,
                          pr: 1,
                        },
                      }}
                    >
                      <Text>{node.content[0].content[0].value}</Text>
                    </li>
                  ),
                },
              }
            )}
          </ul>
        )
      })}
    </div>
  )
}

const FinishedProjectsSection = ({ modelProjectList, ...restProps }) => {
  const isClient = useIsClient()
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const renderProjectCard = projectData => {
    return (
      <ProjectCard
        background={projectData.projectImages[0].file.url}
        link={{
          to: `/projects/${projectData.slug}`,
          type: "internal",
        }}
        text={[projectData.projectTitle.projectTitle].concat(
          projectData.projectLocation.projectLocation
        )}
      />
    )
  }
  const renderProjectList = () => {
    let projectListOutput = null

    if (!isClient || (isClient && mediaQueryMatch)) {
      projectListOutput = modelProjectList.slice(0, 3).map(projectDataNode => {
        const { node: projectData } = projectDataNode

        return (
          <Column size={[12, 12, 4]} key={projectData.id}>
            {renderProjectCard(projectData)}
          </Column>
        )
      })
    } else {
      projectListOutput = (
        <Column>
          <Carousel type="tertiary">
            {modelProjectList.map(projectDataNode => {
              const { node: projectData } = projectDataNode

              return (
                <div key={projectData.id}>{renderProjectCard(projectData)}</div>
              )
            })}
          </Carousel>
        </Column>
      )
    }

    return projectListOutput
  }

  return (
    <Container
      customStyle={{ py: theme => theme.spacing.vertical.lg }}
      {...restProps}
    >
      <Row>
        <Column size={[12, 12, null]}>
          <Text type="h2">
            Finished
            <br />
            Projects
          </Text>
        </Column>
        <Column
          size={[12, 12, null]}
          customStyle={{
            alignItems: ["flex-start", "flex-start", "flex-end"],
            justifyContent: "flex-end",
            mt: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              0,
            ],
          }}
        >
          <div>
            <CTA link="/projects" linkType="internal">
              Explore Project Gallery
            </CTA>
          </div>
        </Column>
      </Row>
      <Row customStyle={{ pt: theme => theme.spacing.vertical.lg }}>
        {renderProjectList()}
      </Row>
    </Container>
  )
}

const ModelStickyHeader = ({ title }) => {
  const sticky = useSticky()
  const headerHeight = 72
  const listItemStyle = Object.assign({}, sharedStyleList.list.item, { mr: 4 })
  const linkStyle = {
    color: "text",
    ...(sticky.action === "pin" && { color: "white" }),
    ":hover": { color: "primary" },
  }

  return (
    <div
      sx={{
        height: headerHeight,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          backgroundColor: "white",
          height: headerHeight,
          width: "100%",
          transition: theme => `background-color ${theme.transitions}`,
          ...(sticky.action === "pin" && {
            bg: "accent",
            position: "fixed",
            top: 0,
            zIndex: 2,
          }),
        }}
      >
        <Container>
          <Row>
            <Column
              customStyle={{ alignItems: "center", flexDirection: "row" }}
            >
              <Text
                customStyle={{
                  alignItems: "center",
                  color: "white",
                  display: ["none", "none", "none", "block"],
                  opacity: 0,
                  pr: [0, 0, theme => theme.spacing.horizontal],
                  transition: theme => `opacity ${theme.transitions}`,
                  ...(sticky.action === "pin" && { opacity: 1 }),
                }}
                type="h3"
              >
                {title}
              </Text>
              <ul
                sx={Object.assign({}, sharedStyleList.list.container, {
                  ml: [0, 0, 0, "auto"],
                  transform: ["none", "none", "none", "translateX(150px)"],
                  transition: theme => `transform ${theme.transitions}`,
                  width: ["100%", "100%", "100%", "auto"],
                  ...(sticky.action === "pin" && {
                    transform: "translateX(0)",
                  }),
                })}
              >
                <li sx={listItemStyle}>
                  <PageLink
                    customStyle={linkStyle}
                    link="#model-overview"
                    target="_self"
                    text="Overview"
                  />
                </li>
                <li sx={listItemStyle}>
                  <PageLink
                    customStyle={linkStyle}
                    link="#technical-details"
                    target="_self"
                    text="Technical"
                  />
                </li>
                <li
                  sx={{
                    ...listItemStyle,
                    display: ["none", "none", "none", "block"],
                  }}
                >
                  <CTA link="/" type="secondary">
                    Customize & Explore in 3D
                  </CTA>
                </li>
                <li sx={{ ...listItemStyle, ml: ["auto", "auto", "auto", 0] }}>
                  <PageLink
                    customStyle={{
                      color: "white",
                      display: ["none", "none", "none", "block"],
                      fontSize: 3,
                      fontWeight: "heading",
                      opacity: 0,
                      transition: theme => `opacity ${theme.transitions}`,
                      whiteSpace: "nowrap",
                      ...(sticky.action === "pin" && { opacity: 1 }),
                    }}
                    link="/"
                    text="Get a Quote >"
                  />
                  <CTA
                    customStyle={{
                      display: ["block", "block", "block", "none"],
                    }}
                    link="/"
                    linkType="internal"
                    type="secondary"
                  >
                    Get a Quote
                  </CTA>
                </li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Flex>
    </div>
  )
}
const TechnicalDetailsContentRow = ({
  children,
  customStyle,
  richText,
  title,
  type,
}) => {
  const renderRichText = () => {
    const richTextContent = richText.json.content.filter(richTextJSONContent =>
      some(
        richTextJSONContent.content,
        richTextContent => richTextContent.value
      )
    )

    return chunk(richTextContent, richTextContent.length / 2).map(
      (richTextGroup, index) => (
        <Column key={index} size={[12, 12, null]}>
          {documentToReactComponents(
            {
              content: richTextGroup,
              data: {},
              nodeType: "document",
            },
            {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => {
                  return node.content[0].value ? (
                    <Text customStyle={{ fontWeight: "bold" }}>{children}</Text>
                  ) : null
                },
              },
            }
          )}
        </Column>
      )
    )
  }

  return (
    <div
      sx={{
        backgroundColor: theme =>
          type && type === "secondary"
            ? "white"
            : theme.colors.backgrounds.primary,
        py: theme => theme.spacing.vertical.sm,
        pb: theme => theme.spacing.vertical.md,
        ...customStyle,
      }}
    >
      <Container>
        <Row>
          <Column
            customStyle={{
              mb: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                0,
              ],
            }}
            size={[12, 12, null]}
          >
            <Text type="h3">{title}</Text>
          </Column>
          {richText && renderRichText()}
          {children}
        </Row>
      </Container>
    </div>
  )
}

const LivingHomeModelDetail = ({ data, location }) => {
  const {
    allContentfulProject: modelProjectList,
    contentfulModelMaster: model,
  } = data

  return (
    <React.Fragment>
      <SEO title={model.title} />
      <ModelStickyHeader title={model.title} />
      {model.model__variation_ && (
        <React.Fragment>
          <Container>
            <Row>
              <Column>
                <SpecificationsOverview
                  pathname={location.pathname}
                  tableContent={[
                    {
                      bodyText: `${getValueRange(
                        model.model__variation_,
                        "beds",
                        true
                      )} Bed | ${getValueRange(
                        model.model__variation_,
                        "baths",
                        true
                      )} Bath`,
                      headingText: "Layout",
                    },
                    {
                      bodyText: `${getValueRange(
                        model.model__variation_,
                        "squareFootage",
                        true
                      )} sq. ft.`,
                      headingText: "Size",
                    },
                  ]}
                  title={model.title}
                />
              </Column>
            </Row>
          </Container>
          {renderCarousel({ standardHome: model.model__variation_ })}
        </React.Fragment>
      )}
      <SpecificationsDetails
        id="model-overview"
        sx={{ py: theme => theme.spacing.vertical.lg }}
        description={model.description && model.description.json}
        richText={model.factoid && model.factoid.json}
        tableContent={[
          {
            bodyText: getValueRange(model.model__variation_, "beds", true),
            headingText: "Bedrooms",
          },
          {
            bodyText: getValueRange(model.model__variation_, "baths", true),
            headingText: "Bathrooms",
          },
          {
            bodyText: `${getValueRange(
              model.model__variation_,
              "squareFootage",
              true
            )} sq. ft.`,
            headingText: "Floor Area",
          },
          {
            bodyText: `${model.modulePrice}`,
            headingText: "Modules",
          },
          {
            bodyText: model.completeEstimate,
            headingText: "Complete Estimate",
          },
        ]}
      />
      {model.model__variation_ && model.model__variation_.length && (
        <div
          id="technical-details"
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            pt: theme => theme.spacing.vertical.lg,
          }}
        >
          <Container>
            <Row>
              <Column>
                <Text type="h2">
                  Technical
                  <br />
                  Details
                </Text>
              </Column>
            </Row>
          </Container>
          <Tabs sx={{ mt: theme => theme.spacing.vertical.md }}>
            <TabList
              sx={{
                maxWidth: "88%",
                mx: [0, 0, "auto"],
                px: theme => [
                  theme.spacing.horizontal,
                  theme.spacing.horizontal,
                  0,
                ],
              }}
            >
              {model.model__variation_.map(standardHome => (
                <Tab key={standardHome.id} text={standardHome.name} />
              ))}
            </TabList>
            <TabPanels>
              {model.model__variation_.map(standardHome => (
                <TabPanel key={standardHome.id}>
                  <div sx={{ backgroundColor: "white" }}>
                    {standardHome.plans && standardHome.plans.length && (
                      <Container>
                        <Row>
                          <Column
                            customStyle={{
                              py: theme => theme.spacing.vertical.lg,
                            }}
                          >
                            <Carousel type="quinary">
                              {standardHome.plans.map((contentData, index) => (
                                <Img
                                  sx={{
                                    maxWidth: 1000,
                                    mx: "auto",
                                  }}
                                  alt={contentData.title}
                                  fluid={contentData.fluid}
                                  key={contentData.id + index}
                                />
                              ))}
                            </Carousel>
                          </Column>
                        </Row>
                      </Container>
                    )}
                  </div>
                  <TechnicalDetailsContentRow title="Specifications">
                    <Column size={[12, 12, null]}>
                      <Text customStyle={{ fontWeight: "bold" }}>
                        {`${findAndReplaceVariableValue(
                          standardHome.beds
                        )} Bedrooms`}
                      </Text>
                      <Text customStyle={{ fontWeight: "bold" }}>
                        {`${findAndReplaceVariableValue(
                          standardHome.baths
                        )} Bathrooms`}
                      </Text>
                    </Column>
                    <Column size={[12, 12, null]}>
                      <Text customStyle={{ fontWeight: "bold" }}>
                        {`${findAndReplaceVariableValue(
                          standardHome.squareFootage
                        )} Square Feet`}
                      </Text>
                      <Text customStyle={{ fontWeight: "bold" }}>
                        {standardHome.width} x {standardHome.length} Total Area
                      </Text>
                    </Column>
                    <Column
                      customStyle={{
                        mt: theme => [
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.sm,
                          0,
                        ],
                      }}
                      size={[12, 12, null]}
                    >
                      {standardHome.plansDownload ? (
                        <div>
                          <CTA type="alt" link={standardHome.plansDownload}>
                            Download Plan
                          </CTA>
                        </div>
                      ) : null}
                    </Column>
                  </TechnicalDetailsContentRow>
                  <TechnicalDetailsContentRow
                    title="Features"
                    richText={standardHome.features}
                    type="secondary"
                  >
                    <Column size={[12, 12, null]} />
                  </TechnicalDetailsContentRow>
                  <TechnicalDetailsContentRow title="Price">
                    <Column size={[12, 12, null]}>
                      <Text customStyle={{ fontWeight: "bold" }}>
                        Standard Modules & Finishes
                      </Text>
                      <Text customStyle={{ fontWeight: "bold" }}>
                        ${standardHome.basePrice} ($
                        {Math.round(
                          standardHome.basePrice / standardHome.squareFootage
                        )}
                        /sq. ft.)
                      </Text>
                    </Column>
                    <Column
                      customStyle={{
                        mt: theme => [
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.sm,
                          0,
                        ],
                      }}
                      size={[12, 12, null]}
                    >
                      <Text customStyle={{ fontWeight: "bold" }}>
                        Estimated total cost
                      </Text>
                      <Text customStyle={{ fontWeight: "bold" }}>
                        ${standardHome.estimate}
                      </Text>
                    </Column>
                    <Column
                      customStyle={{
                        mt: theme => [
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.sm,
                          0,
                        ],
                      }}
                      size={[12, 12, null]}
                    >
                      <div>
                        <CTA type="secondary" link="/">
                          Get a Quote
                        </CTA>
                      </div>
                    </Column>
                  </TechnicalDetailsContentRow>
                  <TechnicalDetailsContentRow
                    title="Sustainability Analysis"
                    type="secondary"
                  >
                    <Column size={[12, 12, null]}>
                      {model.efficiency &&
                        renderEfficiencyRichText(model.efficiency.json)}
                    </Column>
                    <Column
                      customStyle={{
                        mt: theme => [
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.sm,
                          0,
                        ],
                      }}
                      size={[12, 12, null]}
                    >
                      {model.technicalDetailsFactoid &&
                        renderEfficiencyRichText(
                          model.technicalDetailsFactoid.json
                        )}
                    </Column>
                    <Column />
                  </TechnicalDetailsContentRow>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>
      )}
      {model.designPartner && (
        <div
          sx={{
            bg: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          <DesignPartnerDetails
            currentModelMasterSlug={model.slug}
            name={model.designPartner.name}
            photo={model.designPartner.photo}
            relatedModelMasterList={model.designPartner.model__master_}
            shortBio={
              model.designPartner.shortBio && model.designPartner.shortBio.json
            }
            slug={model.designPartner.slug}
          />
        </div>
      )}
      {modelProjectList.edges.length > 0 && (
        <FinishedProjectsSection modelProjectList={modelProjectList.edges} />
      )}
      {data.contentfulBladePattern &&
        data.contentfulBladePattern.contentCards &&
        data.contentfulBladePattern.contentCards.length && (
          <Container customStyle={{ py: theme => theme.spacing.vertical.lg }}>
            <Row>
              <Column>
                <Text customStyle={{ textAlign: "center" }} type="h2">
                  {data.contentfulBladePattern.title}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column>
                <Tabs sx={{ mt: theme => theme.spacing.vertical.lg }}>
                  <TabList>
                    {data.contentfulBladePattern.contentCards.map(
                      contentCard => (
                        <Tab key={contentCard.id} text={contentCard.title} />
                      )
                    )}
                    {data.contentfulBladePattern.ctaDestination &&
                      data.contentfulBladePattern.ctaTitle && (
                        <Tab
                          sx={{ display: ["none", "none", "block"] }}
                          disabled
                        >
                          <CTA
                            type="secondary"
                            link={data.contentfulBladePattern.ctaDestination}
                            linkType="internal"
                          >
                            {data.contentfulBladePattern.ctaTitle}
                          </CTA>
                        </Tab>
                      )}
                  </TabList>
                  <TabPanels>
                    {data.contentfulBladePattern.contentCards.map(
                      contentCard => (
                        <TabPanel key={contentCard.id}>
                          {contentCard.desktopImage && (
                            <div
                              sx={{
                                maxHeight: 350,
                                maxWidth: 980,
                                mx: "auto",
                              }}
                            >
                              <Img
                                fluid={contentCard.desktopImage.fluid}
                                alt={contentCard.desktopImage.title}
                              />
                            </div>
                          )}
                          {contentCard.description &&
                            renderContentCardDescriptionList(
                              contentCard.description
                            )}
                        </TabPanel>
                      )
                    )}
                  </TabPanels>
                </Tabs>
                {data.contentfulBladePattern.ctaDestination &&
                  data.contentfulBladePattern.ctaTitle && (
                    <div
                      sx={{
                        display: ["block", "block", "none"],
                        mt: theme => theme.spacing.vertical.sm,
                      }}
                    >
                      <CTA
                        type="secondary"
                        link={data.contentfulBladePattern.ctaDestination}
                        linkType="internal"
                      >
                        {data.contentfulBladePattern.ctaTitle}
                      </CTA>
                    </div>
                  )}
              </Column>
            </Row>
          </Container>
        )}
    </React.Fragment>
  )
}

export default LivingHomeModelDetail

export const query = graphql`
  query($slug: String!) {
    contentfulModelMaster(slug: { eq: $slug }) {
      completeEstimate
      description {
        json
      }
      designPartner {
        shortBio {
          json
        }
        photo {
          fluid(maxWidth: 150) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        model__master_ {
          id
          model__variation_ {
            baths
            beds
            id
            images {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
              id
              title
            }
            name
            squareFootage
          }
          slug
          title
        }
        name
        slug
      }
      efficiency {
        json
      }
      factoid {
        json
      }
      id
      modulePrice
      model__variation_ {
        id
        basePrice
        baths
        beds
        estimate
        features {
          json
        }
        images {
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        length
        name
        plans {
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        squareFootage
        width
        plansDownload {
          file {
            url
          }
        }
      }
      technicalDetailsFactoid {
        json
      }
      slug
      title
    }

    allContentfulProject(
      sort: { fields: createdAt, order: DESC }
      filter: { model: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          id
          projectTitle {
            projectTitle
          }
          projectLocation {
            projectLocation
          }
          projectImages {
            file {
              url
            }
            id
            title
          }
          slug
        }
      }
    }

    contentfulBladePattern(slug: { eq: "anatomy-of-a-livinghome" }) {
      contentCards {
        ... on ContentfulBasicCard {
          ctaDestination {
            ctaDestination
          }
          ctaTitle
          description {
            json
          }
          desktopImage {
            fluid(maxHeight: 350, maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          id
          title
        }
      }
      title
    }
  }
`
