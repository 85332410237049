/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import chunk from "lodash/chunk"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { Flex } from "@theme-ui/components"

import { renderLineBreak } from "../helpers"

import Column from "./column"
import Container from "./container"
import PageLink from "./page-link"
import Row from "./row"
import Text from "./text"

const SpecificationsDetails = ({
  awards,
  description,
  inTheNews,
  richText,
  tableContent,
  ...restProps
}) => (
  <div {...restProps}>
    <Container>
      {description && (
        <Row customStyle={{ mb: theme => theme.spacing.vertical.sm }}>
          <Column>
            {documentToReactComponents(description, {
              renderNode: {
                [BLOCKS.HEADING_3]: (node, children) => (
                  <Text type="h3">{children}</Text>
                ),
                [BLOCKS.PARAGRAPH]: () => null,
              },
              renderText: text => renderLineBreak(text),
            })}
          </Column>
        </Row>
      )}
      <Row customStyle={{ justifyContent: "space-between" }}>
        <Column size={[12, 12, 4]}>
          {description &&
            documentToReactComponents(description, {
              renderNode: {
                [BLOCKS.HEADING_3]: () => null,
                [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
              },
            })}
          {awards &&
            awards.map(awardData => (
              <Flex
                sx={{
                  alignItems: "center",
                  mt: theme => theme.spacing.vertical.sm,
                }}
                key={awardData.id}
              >
                {awardData.awardLogo && (
                  <img
                    src={awardData.awardLogo.file.url}
                    alt={awardData.awardLogo.title}
                  />
                )}
                {awardData.title && (
                  <Text
                    customStyle={{
                      fontWeight: "heading",
                      maxWidth: ["50%", "50%", "none"],
                      pl: 3,
                    }}
                  >
                    {awardData.title}
                  </Text>
                )}
              </Flex>
            ))}
        </Column>
        <Column
          customStyle={{
            mt: [
              theme => theme.spacing.vertical.md,
              theme => theme.spacing.vertical.md,
              0,
            ],
          }}
          size={[12, 12, 4, 3]}
        >
          <Row>
            {tableContent &&
              chunk(tableContent, Math.ceil(tableContent.length / 2)).map(
                (contentDataGroup, index) => (
                  <Column key={index}>
                    {contentDataGroup.map(
                      (contentData, index) =>
                        contentData.bodyText &&
                        contentData.headingText && (
                          <div
                            sx={{
                              mb: theme => theme.spacing.vertical.sm,
                              ":last-child": { mb: 0 },
                            }}
                            key={index}
                          >
                            <Text customStyle={{ fontWeight: "bold" }}>
                              {contentData.headingText}
                            </Text>
                            <Text>{contentData.bodyText}</Text>
                          </div>
                        )
                    )}
                  </Column>
                )
              )}
          </Row>
        </Column>
        <Column
          customStyle={{
            mt: [
              theme => theme.spacing.vertical.md,
              theme => theme.spacing.vertical.md,
              0,
            ],
          }}
          size={[12, 12, 3]}
        >
          {richText &&
            documentToReactComponents(richText, {
              renderNode: {
                [BLOCKS.HEADING_6]: (node, children) => (
                  <Text customStyle={{ fontWeight: "bold" }}>{children}</Text>
                ),
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Text
                    customStyle={{
                      fontSize: 10,
                      fontWeight: "light",
                    }}
                  >
                    {children}
                  </Text>
                ),
                [BLOCKS.EMBEDDED_ASSET]: node => {
                  const { title, file } = node.data.target.fields

                  return (
                    <div>
                      <img
                        sx={{
                          display: "block",
                          my: theme => theme.spacing.vertical.sm / 2,
                        }}
                        src={file["en-US"].url}
                        alt={title["en-US"]}
                      />
                    </div>
                  )
                },
              },
            })}
          {inTheNews && (
            <div
              sx={{
                ...(richText && {
                  mt: theme => [
                    theme.spacing.vertical.md,
                    theme.spacing.vertical.md,
                    theme.spacing.vertical.sm,
                  ],
                }),
              }}
            >
              <Text
                customStyle={{
                  color: "tertiary",
                  fontSize: 11,
                  fontWeight: "heading",
                  mb: theme => theme.spacing.vertical.sm / 3,
                  textTransform: "uppercase",
                }}
              >
                In The News
              </Text>
              {inTheNews.map((newsData, index) => (
                <div
                  key={index}
                  sx={{
                    mb: theme => theme.spacing.vertical.sm / 2,
                    ":last-child": { mt: 0 },
                  }}
                >
                  <PageLink
                    customStyle={{ display: "block" }}
                    link={newsData.link || "/"}
                  >
                    {newsData.publicationTitle && !newsData.publicationLogo && (
                      <Text customStyle={{ fontWeight: "heading" }}>
                        {newsData.publicationTitle}
                      </Text>
                    )}
                    {newsData.publicationLogo && (
                      <img
                        sx={{ display: "block", maxWidth: 100 }}
                        src={newsData.publicationLogo.file.url}
                        alt={newsData.publicationLogo.title}
                      />
                    )}
                  </PageLink>
                </div>
              ))}
            </div>
          )}
        </Column>
      </Row>
    </Container>
  </div>
)

SpecificationsDetails.propTypes = {
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      awardLogo: PropTypes.shape({
        file: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
        title: PropTypes.string,
      }),
    })
  ),
  description: PropTypes.object,
  inTheNews: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      publicationTitle: PropTypes.string,
      publicationLogo: PropTypes.shape({
        file: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
        title: PropTypes.string,
      }),
    })
  ),
  richText: PropTypes.object,
  tableContent: PropTypes.arrayOf(
    PropTypes.shape({
      bodyText: PropTypes.string,
      headingText: PropTypes.string,
    })
  ),
}

export default SpecificationsDetails
