/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Img from "gatsby-image"

import { renderLineBreak } from "../helpers"

import Carousel from "./carousel"
import Column from "./column"
import Container from "./container"
import ContentCard from "./content-card"
import CTA from "./cta"
import Row from "./row"
import Text from "./text"

const DesignPartnerDetails = ({
  currentModelMasterSlug,
  name,
  photo,
  relatedModelMasterList,
  shortBio,
  slug,
  ...restProps
}) => {
  const renderDesignPartnerModelSection = () => {
    const contentList = []

    relatedModelMasterList.forEach(modelData => {
      if (
        modelData.model__variation_ &&
        modelData.slug !== currentModelMasterSlug
      ) {
        modelData.model__variation_.forEach(modelVariationData => {
          const { images, ...rest } = modelVariationData

          if (images) {
            contentList.push({
              image: images[0],
              modelSlug: modelData.slug,
              ...rest,
            })
          }
        })
      }
    })

    return contentList.length ? (
      <React.Fragment>
        <Row
          customStyle={{
            mt: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Column size={[12, 12, 12, 4]} />
          <Column size={[12, 12, 12, 8]}>
            <Text customStyle={{ maxWidth: 380 }} type="h3">
              Other LivingHomes built and designed by {name}
            </Text>
          </Column>
        </Row>
        <Row
          customStyle={{
            justifyContent: "center",
            mt: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.md,
            ],
          }}
        >
          <Column customStyle={{ maxWidth: ["none", "none", "none", 800] }}>
            <Carousel type="quaternary">
              {contentList.map(contentData => (
                <ContentCard
                  baths={contentData.baths}
                  beds={contentData.beds}
                  imageList={[contentData.image]}
                  squareFootage={contentData.squareFootage}
                  title={contentData.name}
                  titleLink={{
                    to: `/models/${contentData.modelSlug}`,
                    type: "internal",
                  }}
                  key={contentData.id}
                />
              ))}
            </Carousel>
          </Column>
        </Row>
      </React.Fragment>
    ) : null
  }

  return (
    <div {...restProps}>
      <Container>
        <Row>
          <Column
            customStyle={{
              mb: [
                theme => theme.spacing.vertical.sm,
                theme => theme.spacing.vertical.sm,
                theme => theme.spacing.vertical.sm,
                0,
              ],
              order: [0, 0, "unset"],
            }}
            size={[12, 12, 12, 4, 4]}
          >
            <Text type="h2">Design Partner</Text>
          </Column>
          {shortBio && (
            <Column
              customStyle={{ order: [2, 2, "unset"] }}
              size={[12, 12, 12, 4, 4]}
            >
              <Text
                customStyle={{
                  mb: theme => theme.spacing.vertical.sm,
                  pt: 15,
                }}
                type="h3"
              >
                {name}
              </Text>
              {documentToReactComponents(shortBio, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => (
                    <Text
                      customStyle={{
                        mr: theme => theme.spacing.horizontal * 1.5,
                        mb: theme => theme.spacing.vertical.sm,
                        ":last-child": { m: 0 },
                      }}
                    >
                      {children}
                    </Text>
                  ),
                },
                renderText: text => renderLineBreak(text),
              })}
              <div>
                <CTA
                  link={`/architects/${slug}`}
                  linkType="internal"
                  type="secondary"
                >
                  Read Full Bio
                </CTA>
              </div>
            </Column>
          )}
          {photo && (
            <Column
              customStyle={{
                justifyContent: "center",
                mt: theme => [0, 0, theme.spacing.vertical.sm, 0],
                order: [1, 1, "unset"],
              }}
            >
              <Img
                sx={{ maxWidth: [100, 100, 150] }}
                fluid={photo.fluid}
                alt={photo.title}
              />
            </Column>
          )}
        </Row>
        {relatedModelMasterList && renderDesignPartnerModelSection()}
      </Container>
    </div>
  )
}

DesignPartnerDetails.propTypes = {
  currentModelMasterSlug: PropTypes.string.isRequired,
  name: PropTypes.string,
  photo: PropTypes.shape({
    fluid: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  }),
  relatedModelMasterList: PropTypes.arrayOf(
    PropTypes.shape({
      model__variation_: PropTypes.arrayOf(
        PropTypes.shape({
          images: PropTypes.arrayOf(PropTypes.object),
        })
      ),
      slug: PropTypes.string.isRequired,
    })
  ),
  shortBio: PropTypes.object,
  slug: PropTypes.string.isRequired,
}

export default DesignPartnerDetails
