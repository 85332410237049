/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Flex } from "@theme-ui/components"

import PageLink from "./page-link"
import Text from "./text"

import facebookLogo from "../images/facebook.svg"
import shareLink from "../images/facebook.svg"
import twitterLogo from "../images/twitter.svg"

const SpecificationsOverview = ({
  pathname,
  title,
  tableContent,
  ...restProps
}) => {
  const socialLinkList = [
    {
      id: "facebook-link",
      alt: "Link to Facebook",
      href: "https://www.facebook.com/plantprefab",
      src: facebookLogo,
    },
    {
      id: "twitter-link",
      alt: "Link to Twitter",
      href: `https://twitter.com/intent/tweet?text=${title}&url=${encodeURI(
        `https://www.plantprefab.com${pathname}`
      )}`,
      src: twitterLogo,
    },
    {
      id: "share-link",
      alt: "Share",
      href: "#",
      src: shareLink,
    },
  ]
  const styleList = {
    socialLinkList: {
      item: {
        flexShrink: 0,
        m: 0,
        mr: 3,
        ":last-child": { mr: 0 },
      },
    },
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        pt: theme => theme.spacing.vertical.sm,
        pb: theme => [
          theme.spacing.vertical.sm,
          theme.spacing.vertical.sm,
          theme.spacing.vertical.md,
        ],
      }}
      {...restProps}
    >
      <Text customStyle={{ fontSize: [6, 6, 7], maxWidth: ["none", "none", 576] }} type="h1">
        {title}
      </Text>
      <div
        sx={{
          alignItems: "flex-end",
          display: ["block", "block", "flex"],
          mt: [
            theme => theme.spacing.vertical.sm,
            theme => theme.spacing.vertical.sm,
            theme => theme.spacing.vertical.lg,
          ],
        }}
      >
        {tableContent && tableContent.length && (
          <ul
            sx={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              listStyle: "none",
              m: 0,
            }}
          >
            {tableContent.map((contentData, index) =>
              contentData.headingText && contentData.bodyText ? (
                <li
                  sx={{
                    flex: [1, 1, "0 1 auto"],
                    mb: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      0,
                    ],
                    mr: 5,
                  }}
                  key={index}
                >
                  <Text customStyle={{ textTransform: "uppercase" }}>
                    {contentData.headingText}
                  </Text>
                  <Text customStyle={{ fontWeight: "bold" }}>
                    {contentData.bodyText}
                  </Text>
                </li>
              ) : null
            )}
          </ul>
        )}
        <ul
          sx={{
            alignItems: "baseline",
            display: "flex",
            listStyle: "none",
            m: 0,
            ml: [0, 0, "auto"],
          }}
        >
          <li sx={Object.assign({}, styleList.socialLinkList.item, { mr: 4 })}>
            <Text customStyle={{ textTransform: "uppercase" }}>Share:</Text>
          </li>
          {socialLinkList.map(link => (
            <li sx={styleList.socialLinkList.item} key={link.id}>
              <PageLink sx={{ display: "block" }} link={link.href}>
                <img
                  sx={{ display: "block", width: 20 }}
                  src={link.src}
                  alt={link.alt}
                />
              </PageLink>
            </li>
          ))}
        </ul>
      </div>
    </Flex>
  )
}

SpecificationsOverview.propTypes = {
  pathname: PropTypes.string,
  tableContent: PropTypes.arrayOf(
    PropTypes.shape({
      bodyText: PropTypes.string,
      headingText: PropTypes.string,
    })
  ),
  title: PropTypes.string,
}

export default SpecificationsOverview
